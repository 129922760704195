@black: #000000;
@white: #ffffff;
@red: #ef4035;
@green: #3c763d;

.widget-toolbar > .btn-xs {
  padding-left: 5px;
  padding-right: 5px;
}

.clearfix() {
  zoom: 1;
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.no-skin .nav-list li.active > a:after {
  border: none;
}

.topFilter {
  margin-bottom: 10px;
  .form-group {
    margin-right: 10px;
  }
}

/*Table sort*/
.table th[class*="sort"]:after {
  font-family: FontAwesome;
  color: #555555;
  content: "";
  display: inline-block;
  float: right;
  font-size: 13px;
  font-weight: normal;
  margin-right: 4px;
  position: relative;
}

.table th.sorting_asc:after {
  color: #307ECC;
  content: "";
  top: 4px;
}

.table th.sorting_desc:after {
  color: #307ECC;
  content: "";
  top: -6px;
}

.table th[class*="sort"] {
  cursor: pointer;
}

.table th[class*="sort"]:hover {
  color: #547EA8;
}

/*#Table sort*/

/*Image Uploader*/
.image_uploader {
  overflow: hidden;
  .progress {
    display: none;
    height: 34px;
    margin-bottom: 15px;
  }
  .image_manipulator {
    overflow: hidden;
    margin: 15px 0 0 -10px;
    &:empty {
      margin-top: 0;
    }
    .thumb {
      position: relative;
      float: left;
      width: 217px;
      margin-left: 10px;
      margin-bottom: 10px;
      img {
        width: 100%;
      }
      .edit {
        position: absolute;
        top: 0;
        right: 0;
        background: fade(@black, 80%);
        padding: 4px;
        color: white;
      }
      .isMain {
        position: absolute;
        top: 0;
        display: block;
        background: fade(@black, 50%);
        width: 100%;
        text-align: center;
        color: @white;
      }
      .menu {
        position: relative;
        background: fade(@black, 60%);
        z-index: 1000;
        margin-top: -28px;
        padding: 5px;
        a {
          color: @white;
          i {
            margin-right: 5px;
          }
          &.removeThumb i {
            color: @red;
          }
          &.setMainThumb {
            float: right;
            i {
              color: @green;
            }
          }
        }
      }
    }
  }
}

/*Image Uploader*/

.description_relax {
  margin-top: 5px;
  border: 1px solid #b5b5b5;
  padding: 10px;
}

.cropImage {
  .helpPanel {
    padding: 5px;
    margin-top: 10px;
    border: 1px solid #dddddd;
    background: #f9f9f9;
  }
}

.iframe .cropImage {
  margin-top: 5px;
  margin-left: 5px;
}

#previewBox {
  position: fixed;
  right: 10px;
  top: 10px;
  border: 1px solid #b5b5b5;
  display: none;
  z-index: 1005001;
}

.warningBg {
  background: #fcf8e3 !important;

}

.dangerBg {
  background: #f2dede !important;
}

.successBg {
  background: #dff0d8 !important;
}

.infoBg {
  background: #d9edf7 !important;
}

.CodeMirror {
  border: 1px solid #CCCCCC;
}

.subProductImageUploader {
  overflow: hidden;
  .progress {
    display: none;
    height: 34px;
    margin-bottom: 15px;
  }
  .image_manipulator {
    overflow: hidden;
    margin-top: 5px;
    &:empty {
      margin-top: 0;
    }
    .thumb {
      position: relative;
      display: block;
      img {
        width: 100%;
      }
      .menu {
        position: relative;
        background: fade(@black, 60%);
        z-index: 1000;
        margin-top: -28px;
        padding: 5px;
        a {
          color: @white;
          i {
            margin-right: 5px;
          }
          &.removeThumb i {
            color: @red;
          }
          &.setMainThumb {
            float: right;
            i {
              color: @green;
            }
          }
        }
      }
    }
  }
}

.subProducts {
  .name {
    width: 400px;
  }
}